import { defineStore } from "pinia";
import type { Page } from "~/modules/shared/ts/Page";

export const usePageLocaleStore = defineStore("pageLocale", {
  state: () =>
    ({
      pageLocales: undefined,
    }) as {
      pageLocales: Page["attributes"]["localizations"]["data"] | undefined;
    },
});
